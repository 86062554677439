import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <app-loading *ngIf="!loaded"></app-loading>
    <router-outlet *ngIf="loaded"></router-outlet>
  `,
})
export class AppComponent implements OnInit, OnDestroy {
  loaded = false;
  subscription: any;
  subscriptionTranslate: any;

  ngOnInit() {
    this.loaded = true;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscriptionTranslate) {
      this.subscriptionTranslate.unsubscribe();
    }
  }
}
