import { Subscription } from 'rxjs';
import { ChangeDetectorRef, Component, HostListener, Injector, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SessionService } from '@cores/services/session.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NotificationMessageService } from '@cores/services/message.service';
import { Action, ScreenType } from '@cores/utils/enums';
import { LoadingService } from '@cores/services/loading.service';
import * as lodash from 'lodash';

@Component({
  template: ` <ng-content></ng-content>`,
  providers: [DialogService],
})
export class BaseComponent implements OnDestroy {
  title = '';
  screenType?: ScreenType;
  subscription?: Subscription;
  subscriptions: Subscription[] = [];
  stateData: any;
  public loadingService!: LoadingService;
  protected messageService!: NotificationMessageService;
  protected dialogService!: DialogService;
  protected router!: Router;
  protected route!: ActivatedRoute;
  protected location!: Location;
  protected sessionService!: SessionService;
  protected ref!: ChangeDetectorRef;
  protected fb!: FormBuilder;
  protected refDialog!: DynamicDialogRef;
  protected configDialog!: DynamicDialogConfig;

  constructor(public injector: Injector) {
    this.init();
    this.screenType = this.configDialog?.data?.screenType;
    this.stateData = this.configDialog?.data?.state;
    this.title = this.configDialog?.data?.title || '';
    this.subscriptions.push(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.refDialog.close();
        }
      })
    );
  }

  get Action() {
    return Action;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler() {
    window.sessionStorage.clear();
  }

  init() {
    this.messageService = this.injector.get(NotificationMessageService);
    this.dialogService = this.injector.get(DialogService);
    this.fb = this.injector.get(FormBuilder);
    this.router = this.injector.get(Router);
    this.route = this.injector.get(ActivatedRoute);
    this.location = this.injector.get(Location);
    this.sessionService = this.injector.get(SessionService);
    this.ref = this.injector.get(ChangeDetectorRef);
    this.loadingService = this.injector.get(LoadingService);
    this.refDialog = this.injector.get(DynamicDialogRef);
    this.configDialog = this.injector.get(DynamicDialogConfig);
  }

  getValue(obj: any, path: string) {
    return lodash.get(obj, path);
  }

  findDeep(userFunctions: any, functionCode: string, action: Action): boolean {
    for (let userFunction of userFunctions) {
      if (userFunction?.functionCode === functionCode) {
        return userFunction?.authorize?.includes(action);
      }
      if (userFunction?.functions) {
        if (this.findDeep(userFunction.functions, functionCode, action)) {
          return true;
        }
      }
    }
    return false;
  }

  checkRole(_action: Action): boolean {
    return true;
  }

  back() {
    this.location.back();
  }

  cancel() {
    this.refDialog?.close();
  }

  onDestroy() {}

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.subscriptions?.forEach(sub => {
      sub.unsubscribe();
    });
    this.onDestroy();
  }
}
