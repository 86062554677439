import { CommonModule, Location } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { AvatarModule } from 'primeng/avatar';
import { PanelMenuModule } from 'primeng/panelmenu';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToolbarModule } from 'primeng/toolbar';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { FileUploadModule } from 'primeng/fileupload';
import { components } from './components';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { RouterModule } from '@angular/router';
import { InputSwitchModule } from 'primeng/inputswitch';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { RadioButtonModule } from 'primeng/radiobutton';
import { StepsModule } from 'primeng/steps';
import { PickListModule } from 'primeng/picklist';
import { MenubarModule } from 'primeng/menubar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TimelineModule } from 'primeng/timeline';
import { CardModule } from 'primeng/card';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ProgressBarModule } from 'primeng/progressbar';
import { EditorModule } from 'primeng/editor';
import { TreeSelectModule } from 'primeng/treeselect';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { PanelModule } from 'primeng/panel';
import { TabViewModule } from 'primeng/tabview';
import { CarouselModule } from 'primeng/carousel';
import { RippleModule } from 'primeng/ripple';
import { KnobModule } from 'primeng/knob';
import { DividerModule } from 'primeng/divider';
import { BadgeModule } from 'primeng/badge';
import { TagModule } from 'primeng/tag';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AccordionModule } from 'primeng/accordion';
import { RatingModule } from 'primeng/rating';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { ScrollerModule } from 'primeng/scroller';
import { MenuModule } from 'primeng/menu';
import { TreeTableModule } from 'primeng/treetable';
import { SliderModule } from 'primeng/slider';

const COMPONENTS = [...components];

const MODULES = [
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  ButtonModule,
  CheckboxModule,
  InputTextModule,
  CalendarModule,
  AvatarModule,
  PanelMenuModule,
  InputMaskModule,
  InputNumberModule,
  DropdownModule,
  InputTextareaModule,
  MultiSelectModule,
  CalendarModule,
  ToolbarModule,
  DragDropModule,
  TableModule,
  ScrollerModule,
  FileUploadModule,
  PaginatorModule,
  BreadcrumbModule,
  DynamicDialogModule,
  ConfirmPopupModule,
  ConfirmDialogModule,
  ToastModule,
  DialogModule,
  RadioButtonModule,
  InputSwitchModule,
  OrganizationChartModule,
  StepsModule,
  PickListModule,
  MenubarModule,
  OverlayPanelModule,
  SplitButtonModule,
  TimelineModule,
  CardModule,
  ProgressSpinnerModule,
  ProgressBarModule,
  EditorModule,
  TreeSelectModule,
  ScrollPanelModule,
  PanelModule,
  TabViewModule,
  CarouselModule,
  RippleModule,
  KnobModule,
  DividerModule,
  BadgeModule,
  TagModule,
  AutoCompleteModule,
  AccordionModule,
  RatingModule,
  VirtualScrollerModule,
  MenuModule,
  TreeTableModule,
  SliderModule,
];

@NgModule({
  imports: [CommonModule, ...MODULES],
  exports: [CommonModule, ...COMPONENTS, ...MODULES],
  declarations: [...COMPONENTS],
  providers: [Location, DynamicDialogRef, DynamicDialogConfig],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
