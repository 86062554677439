import { Injectable } from '@angular/core';
import { SessionService } from '@cores/services/session.service';
import { Router } from '@angular/router';
import { NotificationMessageService } from '@cores/services/message.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private _sessionService: SessionService,
    private _router: Router,
    private messageService: NotificationMessageService
  ) {}

  isLogged() {
    return localStorage.getItem('isLoggedIn');
  }

  login(body: any) {
    this._sessionService.clearSession();
    if (body.email == 'admin@gmail.com' && body.password == 'admin') {
      localStorage.setItem('isLoggedIn', 'true');
      this._router.navigate(['chat']);
    } else if (!body.email || !body.password) {
      this.messageService.error('Điền đầy đủ thông tin');
    } else {
      this.messageService.error('Thông tin đăng nhập không chính xác');
    }
  }

  logout() {
    this._router.navigate(['login']);
    localStorage.clear();
  }
}
