<span *ngIf="readonly" class="readonly-view">
  <div class="readonly-label">{{ label}}</div>
  <textarea
    *ngIf="control.value"
    [autoResize]="autoResize"
    [formControl]="control"
    [readOnly]="readonly"
    [rows]="rows"
    class="readonly-content"
    pInputTextarea
    style="border: none">
  </textarea>
  <textarea
    *ngIf="!control.value"
    [autoResize]="autoResize"
    [readOnly]="readonly"
    [rows]="rows"
    class="readonly-content"
    pInputTextarea
    style="border: none">---</textarea>
</span>
<span *ngIf="!readonly" class="p-float-label">
  <textarea
    (blur)="onTouched()"
    [autoResize]="autoResize"
    [formControl]="control"
    [ngClass]="{ 'ng-invalid ng-dirty': errors, readonly: readonly }"
    [readOnly]="readonly"
    [rows]="rows"
    pInputTextarea></textarea>
  <label class="block">
    <span *ngIf="showLabel"> {{ label}}</span>
    <span *ngIf="checkRequire()" class="p-errors" style="color: red; margin-left: 3px">*</span></label
  >
  <div class="relative b-errors">
    <ng-container *ngIf="errors">
      <small class="p-error error-msg block fadeinup animation-duration-300">
        <span class="block">{{ getError().key}}</span>
      </small>
    </ng-container>
  </div>
</span>
