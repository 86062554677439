import { BaseComponent } from './base.component';
import { BaseTableComponent } from './base-table.component';
import { LoadingComponent } from './loading/loading.component';
import { ConfirmDialogComponent } from './confirm/confirm.component';
import { LPInputTextComponent } from './lp-input-text/lp-input-text.component';
import { LPInputTextareaComponent } from './lp-input-textarea/lp-input-textarea.component';
import {LoginComponent} from "@shared/components/login/login.component";

export const components = [
  BaseComponent,
  LoadingComponent,
  ConfirmDialogComponent,
  LoginComponent,
  BaseTableComponent,
  LPInputTextComponent,
  LPInputTextareaComponent,
];

export * from './base.component';
export * from './loading/loading.component';
export * from './confirm/confirm.component';
export * from './base-table.component';
