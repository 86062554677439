import { Component, OnInit } from '@angular/core';
import { AuthService } from '@cores/services/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  data: any;
  body: FormGroup = this.fb.group({
    email: [''],
    password: [''],
  });

  constructor(private _authService: AuthService, private fb: FormBuilder) {}

  login() {
    this._authService.login(this.body.value);
  }

  ngOnInit() {
    localStorage.clear();
  }
}
