<div class="container">
    <div class="login-card">
        <img class="logo" src="../../../../assets/images/logo.png">
        <div [formGroup]="body" class="input-container">
            <div class="input-field">
                <lp-input-text formControlName="email" label="Email"></lp-input-text>
            </div>
            <div class="input-field">

                <lp-input-text formControlName="password" label="Mật khẩu"
                               type="password"></lp-input-text>
            </div>
        </div>
        <button (click)="login()" class="login-btn" pButton>Đăng nhập</button>
    </div>

</div>
