<span *ngIf="readonly" class="readonly-view">
  <div class="readonly-label">{{ label}}</div>
  <div *ngIf="!checkControlValue()" class="readonly-content">{{ control.value || '---' }}</div>
</span>
<div *ngIf="showGroup && !readonly" class="p-inputgroup {{groupContentPosition}}">
  <span *ngIf="!readonly" [ngClass]="{ 'p-input-icon-right': searchIcon }" class="p-float-label">
    <em (click)="clickSearch()" *ngIf="searchIcon" class="las la-search"></em>
    <input
      (blur)="onTouched(); trimData()"
      (input)="onInput($event)"
      (keyup.enter)="trimData()"
      *ngIf="!readonly"
      [formControl]="control"
      [ngClass]="{ 'ng-invalid ng-dirty': errors, readonly: readonly }"
      [readOnly]="readonly"
      [type]="type"
      pInputText/>

    <label *ngIf="showLabel">
      <span *ngIf="showLabel"> {{ label }}</span>
      <span *ngIf="checkRequire()" class="p-errors" style="color: red; margin-left: 3px">*</span></label
    >
    <div class="relative b-errors">
      <ng-container *ngIf="errors">
        <small class="p-error error-msg block fadeinup animation-duration-300">
          <span class="block">{{ getError().key }}</span>
        </small>
      </ng-container>
    </div>
  </span>
  <span class="p-inputgroup-addon">{{groupContentPrefix}}{{groupContent ? groupContent : groupPlaceholder}}</span>
</div>

<span *ngIf="!readonly && !showGroup" [ngClass]="{ 'p-input-icon-right': searchIcon }" class="p-float-label">
    <em (click)="clickSearch()" *ngIf="searchIcon" class="las la-search"></em>
    <input
      (blur)="onTouched(); trimData()"
      (input)="onInput($event)"
      (keyup.enter)="trimData()"
      *ngIf="!readonly"
      [formControl]="control"
      [ngClass]="{ 'ng-invalid ng-dirty': errors, readonly: readonly }"
      [readOnly]="readonly"
      [type]="type"
      pInputText/>

    <label *ngIf="showLabel">
      <span *ngIf="showLabel"> {{ label }}</span>
      <span *ngIf="checkRequire()" class="p-errors" style="color: red; margin-left: 3px">*</span></label
    >
    <div class="relative b-errors">
      <ng-container *ngIf="errors">
        <small class="p-error error-msg block fadeinup animation-duration-300">
          <span class="block">{{ getError().key  }}</span>
        </small>
      </ng-container>
    </div>
  </span>
