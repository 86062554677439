import { FormArray, FormControl, FormGroup } from '@angular/forms';

import * as moment from 'moment';
import { DataTable } from '../models/data-table.model';
import { VALIDATORS_KEY } from '@cores/utils/constants';
import * as lodash from 'lodash';

export function getErrorMessage(errorKey: string, errorValue: any, label: string = '') {
  switch (errorKey) {
    case 'required':
      return {
        key: VALIDATORS_KEY['required'],
        required: {
          label: label,
        },
      };
    case 'pattern':
      return {
        key: VALIDATORS_KEY['pattern'],
        required: {
          label: label,
        },
      };
    case 'minTimePresent':
      return {
        key: VALIDATORS_KEY['minTimePresent'],
        required: {
          label: label,
        },
      };
    case 'phonePattern':
      return {
        key: VALIDATORS_KEY['phonePattern'],
        required: {},
      };
    case 'maxlength':
      return {
        key: VALIDATORS_KEY['maxlength'],
        required: {
          requiredLength: errorValue['requiredLength'],
        },
      };
    case 'minlength':
      return {
        key: VALIDATORS_KEY['minlength'],
        required: {
          requiredLength: errorValue['requiredLength'],
        },
      };
    case 'min':
      return {
        key: VALIDATORS_KEY['min'],
        required: {
          min: errorValue['min'],
        },
      };
    case 'max':
      return {
        key: VALIDATORS_KEY['max'],
        required: {
          max: errorValue['max'],
        },
      };
    case 'email':
      return {
        key: VALIDATORS_KEY['email'],
        required: {},
      };
    case 'datePattern':
      return {
        key: VALIDATORS_KEY['datePattern'],
        required: {
          datePattern: errorValue['requiredPattern'],
        },
      };
    case 'minDateAndMaxDate':
      return {
        key: VALIDATORS_KEY['minDateAndMaxDate'],
        required: {
          label: label,
          minDate: errorValue['minDateLabel'],
          maxDate: errorValue['maxDateLabel'],
        },
      };
    case 'minDate':
      return {
        key: VALIDATORS_KEY['minDate'],
        required: {
          label: label,
          minDateLabel: errorValue['minDateLabel'],
        },
      };
    case 'maxDate':
      return {
        key: VALIDATORS_KEY['maxDate'],
        required: {
          label: label,
          maxDateLabel: errorValue['maxDateLabel'],
        },
      };
    case 'maxDateToday':
      return {
        key: VALIDATORS_KEY['maxDateToday'],
        required: {
          label: label,
        },
      };
    case 'maxDateFromNow':
      return {
        key: VALIDATORS_KEY['maxDateFromNow'],
        required: {
          label: label,
          count: errorValue['count'],
          type: errorValue['type'],
        },
      };
    case 'minDateToday':
      return {
        key: VALIDATORS_KEY['minDateToday'],
        required: {
          label: label,
        },
      };
    case 'minAtTheMoment':
      return {
        key: VALIDATORS_KEY['minAtTheMoment'],
        required: {
          label: label,
        },
      };
    case 'requiredLength':
      return {
        key: VALIDATORS_KEY['requiredLength'],
        required: {
          label: label,
          requiredLength: errorValue['requiredLength'],
        },
      };
    default:
      return {
        key: 'EMPTY',
        required: {},
      };
  }
}

export function isValidDate(dateValue: any) {
  return dateValue instanceof Date && !isNaN(dateValue.valueOf());
}

export function isEmpty(value: any) {
  return (
    value === null ||
    value === undefined ||
    value === '' ||
    (Array.isArray(value) && value.length === 0) ||
    (!(value instanceof Date) && typeof value === 'object' && Object.keys(value).length === 0)
  );
}

export function isNotEmpty(value: any) {
  return !isEmpty(value);
}

export function cleanDataForm(formGroup: FormGroup) {
  const form = formGroup;
  Object.keys(form.controls).forEach(field => {
    const control = form.get(field);
    if (control instanceof FormControl && typeof control.value === 'string') {
      control.setValue(control?.value?.trim(), { emitEvent: false });
    } else if (control instanceof FormGroup) {
      cleanDataForm(control);
    } else if (control instanceof FormArray) {
      for (const form of control.controls) {
        cleanDataForm(form as FormGroup);
      }
    }
  });
  return form.getRawValue();
}

export function formatMoment(data: Date | string, currentFormat: string, targetFormat: string) {
  return data ? moment(data, currentFormat).format(targetFormat) : '';
}

export function validateAllFormFields(formGroup?: FormGroup, except?: (FormControl | FormGroup | FormArray)[]) {
  if (!formGroup) {
    return;
  }
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      if (except && !except?.includes(control)) {
        return;
      }
      control.markAsTouched({ onlySelf: true });
      control.markAsDirty({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      if (except && !except?.includes(control)) {
        return;
      }
      validateAllFormFields(control);
    } else if (control instanceof FormArray) {
      if (except && !except?.includes(control)) {
        return;
      }
      for (const form of control.controls) {
        validateAllFormFields(form as FormGroup);
      }
    }
  });
}

export function mapDataTable(data: any, params: any): DataTable {
  return {
    content: data?.list || [],
    size: params?.limit || 10,
    totalElements: data?.count || 0,
    totalPages:
      data.count % params.limit > 0
        ? lodash.floor(data.count / params.limit) + 1
        : lodash.floor(data.count / params.limit),
    first: params?.offset || 0,
  };
}

export function getNodeMenuByUrl(tree: any, url: string): any {
  let result = null;
  if (url === tree.routerLink) {
    return tree;
  } else if (tree.children) {
    for (let treeChild of tree.children) {
      result = getNodeMenuByUrl(treeChild, url);
      if (result) {
        break;
      }
    }
  }
  return result;
}

export function getUrlPathName(url: string) {
  return url.substring(0, url.indexOf('?') > -1 ? url.indexOf('?') : url.length);
}

export function removeParamNullOrUndefined(params: any) {
  const newParams: any = {};
  lodash.forEach(params, (value, key) => {
    if (
      lodash.isBoolean(value) ||
      lodash.isNumber(value) ||
      (!lodash.isNull(value) && !lodash.isUndefined(value) && !lodash.isEmpty(value))
    ) {
      newParams[key] = value;
    }
  });
  return newParams;
}

export function convertToJson(value: string) {
  if (lodash.isString(value) && !lodash.isEmpty(value)) {
    return JSON.parse(value);
  }
  return null;
}

// Convert from Tree Data to Flat Data
export function flattenTreeData(data?: any[]): any[] {
  return lodash.reduce(
    data,
    (result: any[], current) => {
      return [...result, current, ...flattenTreeData(current.children)];
    },
    []
  );
}

export function dataURItoBlob(dataURI: any, type?: string) {
  const byteString = window.atob(dataURI);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  return new Blob([int8Array], { type: type || 'application/octet-stream' });
}

export function getValueDateTimeLocal(value: any): string | null {
  if (lodash.isDate(value)) {
    return moment(value).format(moment.HTML5_FMT.DATETIME_LOCAL);
  }
  return null;
}

export function popResponseError(err: any): string {
  return err.message ? err.message : 'Có lỗi xảy ra';
}

export function convertFileSize(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function removeAscent(str: string) {
  if (str === null || str === undefined) return str;
  str = str.toLowerCase();
  str = str.replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, 'a');
  str = str.replace(/[èéẹẻẽêềếệểễ]/g, 'e');
  str = str.replace(/[ìíịỉĩ]/g, 'i');
  str = str.replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, 'o');
  str = str.replace(/[ùúụủũưừứựửữ]/g, 'u');
  str = str.replace(/[ỳýỵỷỹ]/g, 'y');
  str = str.replace(/đ/g, 'd');
  return str;
}
